import { defineStore } from 'pinia'

import { getTimezones, getCountries } from '@/api/app-routes'
import { getAllAccounts } from '@/api/accounts-routes'

import { useDefaultStore } from '@/store/defaultStore'
import { useAuthorizationStore } from '@/store/authorizationStore'

import { PERMISSION_NAMES } from '@/constants/permissions'

export const useAppStore = defineStore('app', {
    state: () => ({
        timezones: [],
        countries: [],
        loadAccounts: false,
        accounts: [],
        routeIsLoading: false,
    }),

    actions: {
        setRouteLoading(loading) {
            this.routeIsLoading = loading
        },

        getTimezoneOffsetById(id) {
            const timezone = this.timezones.find((timezone) => timezone.id === id)

            return timezone ? timezone.php : null
        },

        async loadTimezones() {
            if (Array.isArray(this.timezones) && this.timezones.length) {
                return
            }

            const { success, payload, message } = await getTimezones()

            if (success) {
                this.timezones = payload
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }
        },

        async loadCountries() {
            if (Array.isArray(this.countries) && this.countries.length) {
                return
            }

            const { success, payload, message } = await getCountries()

            if (success) {
                this.countries = payload
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }
        },

        async loadAccount() {
            const authorizationStore = useAuthorizationStore()

            if (!authorizationStore.permissions[PERMISSION_NAMES.seeAccounts]) {
                return
            }

            this.loadAccounts = true

            const { success, payload, message } = await getAllAccounts()

            if (success) {
                this.accounts = payload.map((el) => {
                    const { owner } = el
                    return {
                        ...el,
                        email: owner.email,
                    }
                })
            } else {
                const defaultStore = useDefaultStore()
                defaultStore.setErrorMessage({ message })
            }

            this.loadAccounts = false
        },
    },
})
