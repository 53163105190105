import { getAccessToken } from '@/helpers/auth-helper'

import { ROUTES } from '@/constants/routes'

export default function error({ next, store }) {
    if (!getAccessToken()) {
        return next({ name: ROUTES.login })
    }

    next(store.getAvailableRouteByPermission())
}
