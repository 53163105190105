import UsersIcon from '@/components/icons/navigations/UsersIcon.vue'
import RolesIcon from '@/components/icons/navigations/RolesIcon.vue'
import LockIcon from '@/components/icons/navigations/LockIcon.vue'
import RatesIcon from '@/components/icons/navigations/RatesIcon.vue'
import PaymentHistoryIcon from '@/components/icons/navigations/PaymentHistoryIcon.vue'
import IncomingIcon from '@/components/icons/navigations/IncomingIcon.vue'
import LogoutIcon from '@/components/icons/navigations/LogoutIcon.vue'
import PhoneNumberIcon from '@/components/icons/navigations/PhoneNumberIcon.vue'
import AdminUsersIcon from '@/components/icons/navigations/AdminUsersIcon.vue'
import AccountsSettingsIcon from '@/components/icons/navigations/AccountsSettingsIcon.vue'
import ActivityLogsIcon from '@/components/icons/navigations/ActivityLogsIcon.vue'
import NumberPoolIcon from '@/components/icons/navigations/NumberPoolIcon.vue'
import DashboardIcon from '@/components/icons/navigations/DashboardIcon.vue'
import AffiliateIcon from '@/components/icons/navigations/AffiliateIcon.vue'
import OutboundTrunkIcon from '@/components/icons/navigations/OutboundTrunkIcon.vue'

import { ROUTES } from '@/constants/routes'
import { PERMISSION_NAMES } from '@/constants/permissions'
import { NAVIGATION_ACTIONS } from '@/constants/navigationActions'

export const MENU_ROUTES = [
    {
        id: 1,
        name: 'Dashboard',
        permissions: [PERMISSION_NAMES.seeDashboard],
        routeName: ROUTES.dashboard,
        icon: () => DashboardIcon,
    },
    {
        id: 2,
        name: 'Admin users',
        permissions: [PERMISSION_NAMES.seeUsers],
        routeName: ROUTES.adminUsers,
        icon: () => AdminUsersIcon,
    },
    {
        id: 3,
        name: 'Roles',
        permissions: [PERMISSION_NAMES.seeRoles],
        routeName: ROUTES.roles,
        icon: () => RolesIcon,
    },
    {
        id: 4,
        name: 'Users',
        icon: () => UsersIcon,
        isOpenSub: false,
        isFirstLoad: true,
        subMenu: [
            {
                name: 'Manage Users',
                routeName: ROUTES.usersList,
                permissions: [PERMISSION_NAMES.seeRegularUsers],
            },
            {
                name: 'Reporting',
                routeName: ROUTES.userReporting,
                permissions: [PERMISSION_NAMES.seeRegularUsers],
            },
        ],
    },
    {
        id: 5,
        name: 'Accounts',
        icon: () => AccountsSettingsIcon,
        isOpenSub: false,
        isFirstLoad: true,
        subMenu: [
            {
                name: 'Manage Accounts',
                routeName: ROUTES.manageAccounts,
                permissions: [PERMISSION_NAMES.seeAccounts],
            },
            {
                name: 'Report Accounts',
                routeName: ROUTES.accountReport,
                permissions: [PERMISSION_NAMES.seeAccountCallsLog],
            },
            {
                name: 'Payment Cards',
                routeName: ROUTES.managePaymentCards,
                permissions: [PERMISSION_NAMES.seeAccounts],
            },
            {
                name: 'Voice Platforms',
                routeName: ROUTES.manageVoicePlatforms,
                permissions: [PERMISSION_NAMES.seeVoicePlatformApplications],
            },
            {
                name: 'Call Record Restore',
                routeName: ROUTES.callRecordRestore,
                permissions: [PERMISSION_NAMES.listenToRecords],
            },
        ],
    },
    {
        id: 6,
        name: 'Rates',
        routeName: ROUTES.accountRates,
        icon: () => RatesIcon,
        isOpenSub: false,
        isFirstLoad: true,
        subMenu: [
            {
                name: 'Account Rates',
                permissions: [PERMISSION_NAMES.seeRates],
                routeName: ROUTES.accountRates,
            },
            {
                name: 'Outbound Rates',
                permissions: [PERMISSION_NAMES.seeVoicePlatformCountryRates],
                routeName: ROUTES.countryRates,
            },
        ],
    },
    {
        id: 7,
        name: 'Outbound Trunk',
        permissions: [PERMISSION_NAMES.seeOutboundTrunks],
        routeName: ROUTES.outboundTrunk,
        icon: () => OutboundTrunkIcon,
    },
    {
        id: 8,
        name: 'Number Permissions',
        permissions: [PERMISSION_NAMES.seePhoneNumberPermissions],
        routeName: ROUTES.numberPermissions,
        icon: () => LockIcon,
    },
    {
        id: 9,
        name: 'Payment History',
        permissions: [PERMISSION_NAMES.seePayments],
        routeName: ROUTES.paymentsHistory,
        icon: () => PaymentHistoryIcon,
    },
    {
        id: 10,
        name: 'Numbers',
        icon: () => PhoneNumberIcon,
        isOpenSub: false,
        isFirstLoad: true,
        subMenu: [
            {
                name: 'Manage Numbers',
                routeName: ROUTES.numbers,
                permissions: [PERMISSION_NAMES.seePhoneNumbers],
            },
            {
                name: 'Number Fees',
                routeName: ROUTES.numberFees,
                permissions: [PERMISSION_NAMES.seePhoneNumbers],
            },
        ],
    },
    {
        id: 11,
        name: 'Incoming Carrier',
        icon: () => IncomingIcon,
        subMenu: [
            {
                name: 'Carrier',
                routeName: ROUTES.incomingCarrier,
                permissions: [PERMISSION_NAMES.seeIncomingCarriers],
            },
            {
                name: 'BYOC',
                routeName: ROUTES.byoc,
                permissions: [PERMISSION_NAMES.seeExternalPhoneNumbers],
            },
            {
                name: 'Banks of Numbers',
                routeName: ROUTES.bankOfNumbers,
                permissions: [PERMISSION_NAMES.seeIncomingCarriers],
            },
        ],
    },
    {
        id: 12,
        name: 'Activity Logs',
        permissions: [PERMISSION_NAMES.seeActivityLogs],
        routeName: ROUTES.activityLogs,
        icon: () => ActivityLogsIcon,
    },
    {
        id: 13,
        name: 'Number Pools',
        permissions: [PERMISSION_NAMES.seeNumberPool],
        routeName: ROUTES.numberPool,
        icon: () => NumberPoolIcon,
    },
    {
        id: 14,
        name: 'Affiliate Program ',
        icon: () => AffiliateIcon,
        isOpenSub: false,
        isFirstLoad: true,
        subMenu: [
            {
                name: 'Affiliates',
                permissions: [PERMISSION_NAMES.seeAffiliateUsers],
                routeName: ROUTES.affiliates,
            },
            {
                name: 'Referrals',
                permissions: [PERMISSION_NAMES.seeAffiliateReferrals],
                routeName: ROUTES.affiliateReferrals,
            },
            {
                name: 'Withdrawal Requests',
                permissions: [PERMISSION_NAMES.seeAffiliateWithdrawals],
                routeName: ROUTES.withdrawalRequests,
            },
        ],
    },
]

export const BOTTOM_MENU_ROUTES = [
    {
        id: 15,
        name: 'Logout',
        action: NAVIGATION_ACTIONS.logout,
        icon: () => LogoutIcon,
    },
]
