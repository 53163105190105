import { MENU_ROUTES } from '@/constants/menu'
import { ROUTES } from '@/constants/routes'

export default function checkPermissions({
    next, store, to, router,
}) {
    const { meta = {} } = to
    const { permission } = meta

    if (permission && !store.checkPermission(permission)) {
        const item = MENU_ROUTES.find((item) => store.checkPermission(item.permission))

        if (item) {
            const { routeName, subMenu = [] } = item
            return router.push({ name: routeName || subMenu[0].routeName })
        }

        return next({ name: ROUTES.login })
    }

    return next()
}
